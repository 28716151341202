@import "common-colors";

@mixin kp-common-button {
  border-radius: $border-radius;
  font-size: 14px;
  gap: 8px;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;

  &:disabled {
    cursor: not-allowed;
  }
}

// Миксины размеров кнопок

@mixin kp-button-long-m {
  @include kp-common-button;
  border-radius: $border-radius;
  height: 42px;
  max-width: 341px;
  padding: 12px 22px;
  width: 100%;
}

@mixin kp-button-m {
  @include kp-common-button;
  padding: 12px 22px;
  height: 42px;
}

@mixin kp-button-s {
  @include kp-common-button;
  padding: 7px 16px;
  height: 32px;
}

@mixin kp-text-button-s {
  @include kp-common-button;
  height: 18px;
  font-size: 13px;
  border-radius: 2px;
  padding: 0 8px;
}

@mixin kp-text-button-m {
  @include kp-common-button;
  height: 24px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 2px;
  padding: 0 8px;
  gap: 2px;
}

// Миксины типов кнопок

@mixin kp-button-primary {
  border: none;
  background: $primary;
  color: $white;

  &:hover {
    background: $primary-hover;
  }

  &:active {
    background: $primary-active;
  }

  &:disabled {
    background: $primary-disabled;
  }
}

@mixin kp-button-stroke {
  border: 1px solid $border;
  background: $white;

  &:hover, &:active {
    border-color: $border-hovered;
  }

  &:disabled {
    border-color: $border-hovered;
    color: $border-hovered;
    background: $neutral-disabled;
  }
}

@mixin kp-text-button {
  border: none;
  background-color: transparent;
  color: $primary;
  width: fit-content;
  font-weight: 500;

  &:hover {
    color: $primary-hover;
  }

  &:active {
    color: $primary-active;
  }

  &:disabled {
    background: $primary-disabled;
  }
}

// Наборы стилей для кнопок формата "kp-<тип>-button-<размер>"

// primary

.kp-primary-button-long-m {
  @include kp-button-long-m;
  @include kp-button-primary;
}

.kp-primary-button-m {
  @include kp-button-m;
  @include kp-button-primary;
}

.kp-primary-button-s {
  @include kp-button-s;
  @include kp-button-primary;
}

// stroked

.kp-stroked-button-long-m {
  @include kp-button-long-m;
  @include kp-button-stroke;
}

.kp-stroked-button-m {
  @include kp-button-m;
  @include kp-button-stroke;
}

.kp-stroked-button-s {
  @include kp-button-s;
  @include kp-button-stroke;
}

// text

.kp-text-button-s {
  @include kp-text-button-s;
  @include kp-text-button;
}

.kp-text-button-non-color-s {
  @include kp-text-button-s;
  @include kp-text-button;

  color: rgba(121, 126, 140, 1);
}

.kp-text-button-m {
  @include kp-text-button-m;
  @include kp-text-button;
}
